import { API_URL, DEFAULT_REQUEST_CONFIG } from "../../global";
import { constructInitRequestConfig, defaultFetcher } from "../../helpers";

/**
 * @description Añade un artículo al carrito.
 * @param {string} language Idioma.
 * @param {string} token Token para autenticar la petición.
 * @param {string} productCode Código del producto a añadir.
 * @param {number} quantity Cantidad de unidades a añadir.
 * @param {string} type Tipo de acción: add para añadir unidades o set para actualizar (acción por defecto en su API).
 * @param {string} location localizacion: vacio si no es un outlet, relleno para indicar de donde viene el OUTLET (acción por defecto en su API).
 * @returns {Promise}
 */
export async function addItem(
  language,
  token,
  productCode,
  quantity,
  type,
  location = "",
) {
  /* localStorage.setItem("cartLoad", "loading"); */
  return defaultFetcher(
    `${API_URL}/carrito`,
    constructInitRequestConfig(
      { ...DEFAULT_REQUEST_CONFIG, method: "POST" },
      {
        lng: language,
        fstoken: token,
        articulo: productCode,
        cantidad: quantity,
        tipo: type,
        localizacion: location,
      },
    ),
  );
}
