export const Wrapper = ({ Component = undefined, id, children, ...props }) => {
  return Component !== undefined ? (
    <Component id={id} {...props}>
      {children}
    </Component>
  ) : (
    <section id={id} {...props}>
      {children}
    </section>
  );
};
