import { DEFAULT_REQUEST_CONFIG } from "../../global";
import { constructInitRequestConfig, defaultFetcher } from "../../helpers";

/**
 * @description Sugerencias de búsqueda.
 * @param {string} lang Idioma seleccionado.
 * @param {string} query Búsqueda realizada.
 * @param {number} limit Límite de resultados.
 * @param {number} offset Resultado inicial.
 * @returns {Promise}
 */
export async function getSuggestions(lang, query, limit = 5, offset = 0) {
  return defaultFetcher(
    `${process.env.REACT_APP_API_BASE_URL}/search`,
    constructInitRequestConfig(
      {
        ...DEFAULT_REQUEST_CONFIG,
        credentials: "omit",
        method: "POST",
      },
      {},
      {
        idioma: lang,
        keyword: query,
        tipo_resultado: "resultado_productos",
        mode: "sugerencias",
        limit: limit,
        offset: offset,
      }
    )
  );
}
