import "./styles/SubsectionTitle.scss";

export const SubsectionTitle = ({ title, content }) => {
  return (
    <div className="components-ui-subsection-title">
      <div>
        <h2 className="text-xs">{title}</h2>
        <div>{content}</div>
      </div>
    </div>
  );
};
