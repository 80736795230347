import { Card } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const RankListSkeleton = () => {
  return (
    <div className="flex-container-span">
      <span className="span-rank">
        <p className="with-p special-color">
          <Skeleton width={20} height={20} />
        </p>
      </span>
      <Card className="components-ui-product-simple-card  horizontal">
        <Card.Body>
          <div className="image-frame">
            <div className="image-frame-wrapper">
              <Skeleton width={94} height={94} />
            </div>
          </div>

          <div className="info-masvendidos">
            <small className="ecommerce-sidebar-link text-brand-gray-dark text-color-hover-primary text-uppercase">
              <Skeleton /> <Skeleton />
            </small>
            <h4 className="text-4 line-height-2 ecommerce-sidebar-link text-color-primary fw-bold my-1 flex-container-space">
              <Skeleton />

              <Skeleton />
            </h4>
            <div className="d-flex flex-row gap-2 flex-wrap">
              <Skeleton />
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};
