import { API_URL, DEFAULT_REQUEST_CONFIG } from "../../global";
import { constructInitRequestConfig, defaultFetcher } from "../../helpers";

/**
 * @description Elimina un artículo de los favoritos.
 * @param {string} language Idioma.
 * @param {string} token Token para autenticar la petición.
 * @param {string} productCode Código del producto a eliminar.
 * @returns {Promise}
 */
export async function removeItem(language, token, productCode) {
  return defaultFetcher(
    `${API_URL}/favoritos`,
    constructInitRequestConfig(
      { ...DEFAULT_REQUEST_CONFIG, method: "DELETE" },
      {
        lng: language,
        fstoken: token,
        articulo: productCode,
      }
    )
  );
}
