import { API_URL, DEFAULT_REQUEST_CONFIG } from "../../global";
import { constructInitRequestConfig, defaultFetcher } from "../../helpers";

/**
 * @description Elimina un artículo del carrito.
 * @param {string} language Idioma.
 * @param {string} token Token para autenticar la petición.
 * @param {string} productCode Código del producto a eliminar.
 * @param {string} location Localización del producto para determinar si es Outlet.
 * @returns {Promise}
 */
export async function removeItem(language, token, productCode, location = "") {
  return defaultFetcher(
    `${API_URL}/carrito`,
    constructInitRequestConfig(
      { ...DEFAULT_REQUEST_CONFIG, method: "DELETE" },
      {
        lng: language,
        fstoken: token,
        articulo: productCode,
        localizacion: location,
      },
    ),
  );
}
