import React from "react";
import ReactDOM from "react-dom/client";

import App from "./components/App";
import "./i18n";

import "./custom.scss";

import "./assets/css/theme.css";
import "./assets/css/extension.css";
import "./assets/css/custom.scss";
import "./assets/css/keyframes.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
