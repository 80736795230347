import { API_URL, DEFAULT_REQUEST_CONFIG } from "../global";
import { constructInitRequestConfig, defaultFetcher } from "../helpers";

/**
 * @description Devuelve la información del usuario autenticado.
 * @param {string} token Token de seguridad.
 * @param {string} lng Lenguage del recurso solicitado.
 * @param {object} headers Cabeceras extras a añadir.
 * @returns {Promise}
 */
export async function currentUser(token, lng, headers = {}) {
  return defaultFetcher(
    `${API_URL}/user`,
    constructInitRequestConfig(DEFAULT_REQUEST_CONFIG, {
      lng: lng,
      fstoken: token,
      ...headers,
    }),
  );
}
