import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Carousel as BsCarousel, Image } from "react-bootstrap";
import { useMediaQuery } from "usehooks-ts";
import SimpleBar from "simplebar-react";
import FsLightbox from "fslightbox-react";

import { Wrapper } from "../ui";
import { BREAKPOINTS } from "../../global";

import "./styles/Carousel.scss";
import "simplebar-react/dist/simplebar.min.css";

export const Carousel = ({ images }) => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);
  const isGreatherThanXL = useMediaQuery(`(min-width: ${BREAKPOINTS.xl}px)`);
  const [toggler, setToggler] = useState(false);

  const handleActiveIndex = (idx) => {
    setActiveIndex(idx);
  };

  return (
    <Wrapper className="components-ui-carousel">
      <BsCarousel
        activeIndex={activeIndex}
        indicators={!isGreatherThanXL}
        interval={isGreatherThanXL ? null : 3000}
        nextLabel={t("siguiente")}
        prevLabel={t("anterior")}
        onSelect={(idx) => handleActiveIndex(idx)}
        fade
      >
        {images.map((image, idx) => (
          <BsCarousel.Item key={idx}>
            <Image
              className="d-block w-100"
              src={image}
              alt={`Slide ${idx}`}
              loading="lazy"
              decoding="async"
              onClick={() => setToggler(!toggler)}
            />
          </BsCarousel.Item>
        ))}
      </BsCarousel>

      {isGreatherThanXL && (
        <SimpleBar className="mw-100">
          <div className="carousel-thumbnails">
            {images.map((image, idx) => {
              return (
                <figure
                  key={idx}
                  role="button"
                  className={activeIndex === idx ? "active" : ""}
                  onClick={() => handleActiveIndex(idx)}
                >
                  <Image
                    src={image}
                    alt={`${t("miniatura")} ${idx}`}
                    loading="lazy"
                    decoding="async"
                  />
                </figure>
              );
            })}
          </div>
        </SimpleBar>
      )}

      <FsLightbox
        toggler={toggler}
        sources={images.map((image) => image)}
        type="image"
      />
    </Wrapper>
  );
};
