import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

i18n
  /**
   * @description Carga las traducciones utilizando HTTP
   * @see /public/locales
   * @example https://github.com/i18next/react-i18next/tree/master/example/react/public/locales
   */
  .use(Backend)
  /**
   * @description Detecta el idioma del usuario.
   * @see https://github.com/i18next/i18next-browser-languageDetector
   */
  .use(LanguageDetector)
  /**
   * @description Pasa la instancia i18n a react-i18next.
   */
  .use(initReactI18next)
  /**
   * @description Inicializa i18next.
   * @see https://www.i18next.com/overview/configuration-options Para ver todas las opciones.
   */
  .init({
    debug: process.env.REACT_APP_ENVIRONMENT !== "production",
    load: "languageOnly",
    detection: {
      order: ["localStorage", "navigator"],
    },
    fallbackLng: ["es", "en", "fr", "pt"],
    supportedLngs: ["es", "en", "fr", "pt"],
    nonExplicitSupportedLngs: true,
    cleanCode: true,
    returnNull: false,
    initImmediate: false,
  });

export default i18n;
