import { useContext } from "react";
import NotificationsContext from "../../context/Notifications";
import { Notification } from "./Notification";

export const Notifications = () => {
  const { notifications, removeNotification } =
    useContext(NotificationsContext);

  return (
    notifications.length > 0 && (
      <section
        style={{
          width: "288px",
          position: "fixed",
          top: "1rem",
          right: "1rem",
          zIndex: "10000",
        }}
      >
        {notifications.map((notification) => {
          return (
            <Notification
              key={`notification-${notification.id}`}
              title={notification.title}
              text={notification.text}
              variant={notification.variant}
              handleClick={() => removeNotification(notification.id)}
            />
          );
        })}
      </section>
    )
  );
};
