export const Overlay = () => {
  return (
    <section className="loading-overlay-showing" data-loading-overlay>
      <div className="loading-overlay bg-transparent">
        <div className="bounce-loader">
          <div className="bounce1 bg-primary"></div>
          <div className="bounce2 bg-primary"></div>
          <div className="bounce3 bg-primary"></div>
        </div>
      </div>
    </section>
  );
};
