import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Card } from "react-bootstrap";
export const CatalogSkeleton = () => {
  return (
    <>
      <div className="grid">
        <div className="tile-wrapper p-relative">
          <Skeleton width={1100} height={450} />
          <div>
            <div className="marker"></div>
            <span className="tile-title h2">
              <Skeleton width={1100} />
            </span>
            <span className="h6 fw-light">
              {" "}
              <Skeleton width={1100} />
            </span>
            <span className="h6 fw-bold">
              <Skeleton width={1100} />
            </span>
          </div>
        </div>
      </div>

      <section className="bottom-category">
        <div>
          {Array(5)
            .fill("loading")
            .map((_, index) => (
              <Card key={index} className="mt-0">
                <Card.Body className="p-0 d-flex flex-column">
                  <div className="image-frame border border-brand-gray-light">
                    <div className="image-frame-wrapper">
                      <Skeleton height={90} width={90} />
                    </div>
                  </div>
                  <div className="d-flex flex-column bg-brand-gray-light p-3 flex-grow-1 text-center">
                    <h4 className="text-4 line-height-2 ecommerce-sidebar-link text-brand-gray-dark fw-bold m-0 mb-1">
                      <Skeleton width={90} />
                    </h4>
                    <small className="text-brand-gray-dark m-0 mb-2">
                      <Skeleton width={90} />
                    </small>
                    <p className="mt-auto mb-0 text-uppercase text-1">
                      <Skeleton width={90} />
                    </p>
                  </div>
                </Card.Body>
              </Card>
            ))}
        </div>
      </section>
    </>
  );
};
