import useSWR, { useSWRConfig } from "swr";
import { useTranslation } from "react-i18next";

import useAuth from "./useAuth";

import {
  constructInitRequestConfig,
  extractItemsFromApiRequest,
} from "../helpers";
import {
  API_URL,
  DEFAULT_REQUEST_CONFIG,
  SECTION_PERMISSIONS,
} from "../global";

const useRmas = (headers = {}) => {
  const { i18n } = useTranslation();
  const { canAccess, token } = useAuth();
  const { mutate } = useSWRConfig();

  const config = [
    `${API_URL}/rmas`,
    constructInitRequestConfig(DEFAULT_REQUEST_CONFIG, {
      lng: i18n.resolvedLanguage,
      fstoken: token.value,
      ...headers,
    }),
  ];

  const { data } = useSWR(canAccess(SECTION_PERMISSIONS.rmas) ? config : null);

  return {
    data: data ? extractItemsFromApiRequest(data, false) : [],
    total: data ? data.TotalItems : 0,
    mutate: () => mutate(config),
  };
};

export default useRmas;
