import { Fragment, lazy, Suspense } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";

import { PUBLIC_ROUTES, ROUTES, SECTION_PERMISSIONS } from "../global";
import useAuth from "../hooks/useAuth";
import { CartProvider } from "../context/Cart";
import { FavsProvider } from "../context/Favs";
import { PricesProvider } from "../context/Prices";
import { Overlay } from "./ui";
import { RmaProvider } from "../context/Rma";

import { useEffect } from "react";

const Layout = lazy(() => import("../components/Layout"));
const Dashboard = lazy(() => import("../pages/Dashboard"));
const Login = lazy(() => import("../pages/Login"));
const ResetPass = lazy(() => import("../pages/ResetPass"));
const SignUp = lazy(() => import("../pages/SignUp"));
const Maintenance = lazy(() => import("../pages/Maintenance"));

/* ******* */
/* CARRITO */
/* ******* */
const CartIndex = lazy(() => import("../pages/Cart/index"));
const CartThanks = lazy(() => import("../pages/Cart/thanks"));

/* ******* */
/* USUARIO */
/* ******* */
const UsersProfile = lazy(() => import("../pages/Users/profile"));
const Clients = lazy(() => import("../pages/Users/clients"));

/* **** */
/* RMAs */
/* **** */
const RmasIndex = lazy(() => import("../pages/Rmas/index"));
const RmasNew = lazy(() => import("../pages/Rmas/new"));
const RmasEdit = lazy(() => import("../pages/Rmas/edit"));

/* ******* */
/* PEDIDOS */
/* ******* */
const OrdersIndex = lazy(() => import("../pages/Orders/index"));
const OrdersView = lazy(() => import("../pages/Orders/view"));

/* ***************************************** */
/* PRESUPUESTOS --> COTIZACIONES + PROYECTOS */
/* ***************************************** */
const BudgetsIndex = lazy(() => import("../pages/Budgets/index"));
const BudgetsView = lazy(() => import("../pages/Budgets/view"));
const NewBudgetIndex = lazy(() => import("../pages/Budgets/NewBudget/index"));
const NewBudgetThanks = lazy(() => import("../pages/Budgets/NewBudget/thanks"));

/* ********* */
/* PRODUCTOS */
/* ********* */
const ProductsView = lazy(() => import("../pages/Products/view"));

/* ******** */
/* NOVEDADES */
/* ******** */
const Novedades = lazy(() => import("../pages/Novedades/index"));
const MasVendidos = lazy(() => import("../pages/MasVendidos/index"));

/* ******** */
/* BUSCADOR */
/* ******** */
const SearchIndex = lazy(() => import("../pages/Search/index"));

/* ********** */
/* CATEGORÍAS */
/* ********** */
const CategoriesIndex = lazy(() => import("../pages/Categories/index"));
const SubcategoriesIndex = lazy(
  () => import("../pages/Categories/subcategories"),
);
const SeriesIndex = lazy(() => import("../pages/Categories/series"));
const SerieIndex = lazy(() => import("../pages/Categories/serie"));

/* *********** */
/* PROMOCIONES */
/* *********** */
const Promociones = lazy(() => import("../pages/Promociones/index"));

/* ********* */
/* ESTÁTICAS */
/* ********* */
const WebServicesIndex = lazy(() => import("../pages/WebServices/index"));
const WebServicesThanks = lazy(() => import("../pages/WebServices/thanks"));
const FonestarFidelityIndex = lazy(
  () => import("../pages/FonestarFidelity/index"),
);

/* ******** */
/* CONTACTO */
/* ******** */
const ContactIndex = lazy(() => import("../pages/Contact/index"));
const Help = lazy(() => import("../pages/Contact/help"));
const Import = lazy(() => import("../pages/Contact/import"));

/* ***** */
/* LEGAL */
/* ***** */
const LegalNotice = lazy(() => import("../pages/Legal/notice"));
const LegalRma = lazy(() => import("../pages/Legal/rma"));
const LegalWarranty = lazy(() => import("../pages/Legal/warranty"));

/* *************** */
/* FUERAS DE SERIE */
/* *************** */
const FuerasDeSeriePromos = lazy(() => import("../pages/FuerasDeSerie/promos"));
const FuerasDeSerieSuperPromos = lazy(
  () => import("../pages/FuerasDeSerie/superPromos"),
);
const FuerasDeSerieMegaPromos = lazy(
  () => import("../pages/FuerasDeSerie/megaPromos"),
);
const FuerasDeSerieAgujas = lazy(() => import("../pages/FuerasDeSerie/agujas"));

/* ****** */
/* OUTLET */
/* ****** */
const OutletDiscontinued = lazy(() => import("../pages/Outlet/discontinued"));
const OutletUsed = lazy(() => import("../pages/Outlet/used"));
const OutletPacking = lazy(() => import("../pages/Outlet/packing"));

/* ****** */
/* ERRORS */
/* ****** */
const PageNotFound = lazy(() => import("../pages/Errors/404"));
const GeneralError = lazy(() => import("../pages/Errors/500"));

// https://dev.to/finiam/predictable-react-authentication-with-the-context-api-g10
// https://github.com/finiam/phoenix_starter/
// https://react.i18next.com/latest/using-with-hooks

/**
 * @see https://stackblitz.com/github/remix-run/react-router/tree/main/examples/auth?file=src/App.tsx
 * @returns
 */
const PrivateOutlet = () => {
  const { isLogged } = useAuth();
  const { i18n } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  if (!isLogged()) {
    return (
      <Navigate
        to={`/${ROUTES[i18n.resolvedLanguage].login}`}
        state={{ from: location.pathname }}
      />
    );
  }

  return (
    <Suspense fallback={<Overlay />}>
      <PricesProvider>
        <CartProvider>
          <FavsProvider>
            <RmaProvider>
              <Layout>
                <Outlet />
              </Layout>
            </RmaProvider>
          </FavsProvider>
        </CartProvider>
      </PricesProvider>
    </Suspense>
  );
};

const Router = () => {
  const { i18n } = useTranslation();
  const { canAccess, canAccessFidelity } = useAuth();

  return (
    <Routes>
      <Route path="*" element={<PageNotFound />} />
      <Route path="/404" element={<PageNotFound />} />
      <Route path="/" element={<PrivateOutlet />}>
        {/* DASHBOARD */}
        <Route element={<Dashboard />} index />

        {i18n.languages.map((locale) => {
          return (
            <Fragment key={`private-routes-${locale}`}>
              {/* ****** */}
              {/* PERFIL */}
              {/* ****** */}
              <Route
                path={ROUTES[locale].profile.index}
                element={<UsersProfile />}
              />

              <Route path={ROUTES[locale].clients} element={<Clients />} />

              {/* ******* */}
              {/* CARRITO */}
              {/* ******* */}
              <Route path={ROUTES[locale].cart.index}>
                <Route element={<CartIndex />} index />
                <Route
                  path={ROUTES[locale].cart.thanks}
                  element={<CartThanks />}
                />
              </Route>

              {/* *** */}
              {/* RMA */}
              {/* *** */}
              {canAccess(SECTION_PERMISSIONS.rmas) && (
                <Route path={ROUTES[locale].rmas.index}>
                  <Route element={<RmasIndex />} index />
                  <Route
                    path={ROUTES[locale].rmas.nuevo}
                    element={<RmasNew />}
                  />
                  <Route
                    path={ROUTES[locale].rmas.estado}
                    element={<RmasIndex />}
                  />
                  <Route path=":rmaCode" element={<RmasEdit />} />
                </Route>
              )}

              {/* ********** */}
              {/* CATEGORÍAS */}
              {/* ********** */}
              <Route path={ROUTES[locale].categories}>
                <Route element={<CategoriesIndex />} index />
                <Route path=":categorySlug">
                  <Route element={<SubcategoriesIndex />} index />
                  <Route path=":subcategorySlug">
                    <Route element={<SeriesIndex />} index />
                    <Route path=":serieSlug" element={<SerieIndex />} />
                  </Route>
                </Route>
              </Route>

              {/* ********* */}
              {/* PRODUCTOS */}
              {/* ********* */}
              <Route path={ROUTES[locale].products}>
                <Route path=":productSlug" element={<ProductsView />} />
              </Route>

              {/* ******** */}
              {/* BUSCADOR */}
              {/* ******** */}
              <Route path={ROUTES[locale].search} element={<SearchIndex />} />

              {/* ******* */}
              {/* PEDIDOS */}
              {/* ******* */}
              {canAccess(SECTION_PERMISSIONS.orders) && (
                <Route path={ROUTES[locale].orders.index}>
                  <Route element={<OrdersIndex />} index />
                  <Route path=":orderCode" element={<OrdersView />} />
                </Route>
              )}

              {/* *************************************** */}
              {/* PRESUPUESTOS = COTIZACIONES + PROYECTOS */}
              {/* *************************************** */}

              <Route path={ROUTES[locale].budgets.index}>
                <Route element={<BudgetsIndex />} index />
                <Route path=":budgetCode">
                  <Route element={<BudgetsView />} index />
                </Route>
              </Route>

              <Route path={ROUTES[locale].newBudget.index}>
                <Route element={<NewBudgetIndex />} index />

                {
                  <Route
                    path={ROUTES[locale].newBudget.thanks}
                    element={<NewBudgetThanks />}
                  />
                }
              </Route>

              {/* ****** */}
              {/* OUTLET */}
              {/* ****** */}
              <Route path={ROUTES[locale].outlet.index}>
                <Route
                  path={ROUTES[locale].outlet.discontinued}
                  element={<OutletDiscontinued />}
                />
                <Route
                  path={ROUTES[locale].outlet.used}
                  element={<OutletUsed />}
                />
                <Route
                  path={ROUTES[locale].outlet.packing}
                  element={<OutletPacking />}
                />
              </Route>

              {/* ********** */}
              {/* NOVEDADES  */}
              {/* ********** */}
              <Route path={ROUTES[locale].novedades} element={<Novedades />} />
              <Route
                path={ROUTES[locale].masvendidos}
                element={<MasVendidos />}
              />

              {/* *************** */}
              {/* FUERAS DE SERIE */}
              {/* *************** */}
              <Route path={ROUTES[locale].fuerasDeSerie.index}>
                <Route
                  path={ROUTES[locale].fuerasDeSerie.promos}
                  element={<FuerasDeSeriePromos />}
                />
                <Route
                  path={ROUTES[locale].fuerasDeSerie.megaPromos}
                  element={<FuerasDeSerieMegaPromos />}
                />
                <Route
                  path={ROUTES[locale].fuerasDeSerie.superPromos}
                  element={<FuerasDeSerieSuperPromos />}
                />
                <Route
                  path={ROUTES[locale].fuerasDeSerie.agujas}
                  element={<FuerasDeSerieAgujas />}
                />
              </Route>

              {/* *********** */}
              {/* PROMOCIONES */}
              {/* *********** */}
              <Route path=":promoSlug" element={<Promociones />} />

              {/* ******** */}
              {/* CONTACTO */}
              {/* ******** */}
              <Route path={ROUTES[locale].contact} element={<ContactIndex />} />
              <Route path={ROUTES[locale].help} element={<Help />} />
              <Route path={ROUTES[locale].import} element={<Import />} />

              {/* ************ */}
              {/* WEB SERVICES */}
              {/* ************ */}
              <Route path={ROUTES[locale].webServices.index}>
                <Route element={<WebServicesIndex />} index />
                {
                  <Route
                    path={ROUTES[locale].webServices.thanks}
                    element={<WebServicesThanks />}
                  />
                }
              </Route>

              {/* *********** */}
              {/* ANNIVERSARY */}
              {/* *********** */}
              {canAccessFidelity() && (
                <Route
                  path={ROUTES[locale].fonestarFidelity}
                  element={<FonestarFidelityIndex />}
                />
              )}

              {/* ***** */}
              {/* LEGAL */}
              {/* ***** */}
              <Route path={ROUTES[locale].legal.index}>
                <Route element={<LegalNotice />} index />
                <Route
                  path={ROUTES[locale].legal.warranty}
                  element={<LegalWarranty />}
                />
                <Route path={ROUTES[locale].legal.rma} element={<LegalRma />} />
              </Route>

              <Route path="error" element={<GeneralError />} />
            </Fragment>
          );
        })}
      </Route>

      {i18n.languages.map((locale) => {
        return (
          <Fragment key={`public-routes-${locale}`}>
            {/* ***** */}
            {/* LOGIN */}
            {/* ***** */}
            <Route
              path={ROUTES[locale].login}
              element={
                <Suspense fallback={<Overlay />}>
                  <Login />
                </Suspense>
              }
            />

            {/* ********** */}
            {/* RESET PASS */}
            {/* ********** */}
            <Route
              path={PUBLIC_ROUTES[locale].reset}
              element={
                <Suspense fallback={<Overlay />}>
                  <ResetPass />
                </Suspense>
              }
            />

            {/* ******* */}
            {/* SIGN UP */}
            {/* ******* */}
            <Route
              path={PUBLIC_ROUTES[locale].signUp}
              element={
                <Suspense fallback={<Overlay />}>
                  <SignUp />
                </Suspense>
              }
            />

            {/* *********** */}
            {/* MAINTENANCE */}
            {/* *********** */}
            <Route
              path={PUBLIC_ROUTES[locale].maintenance}
              element={
                <Suspense fallback={<Overlay />}>
                  <Maintenance />
                </Suspense>
              }
            />
          </Fragment>
        );
      })}
    </Routes>
  );
};

export default Router;
