import { useTranslation } from "react-i18next";
export const Wizard = ({ steps, currentStep, handleStep = () => {} }) => {
  const { t } = useTranslation();
  return (
    <div className="wizard-progress wizard-progress-lg">
      <div className="steps-progress">
        <div className="progress-indicator"></div>
      </div>
      <ul className="nav wizard-steps">
        {steps.map((step) => {
          return (
            <li
              key={step.idx}
              className={`nav-item${
                currentStep === step.idx
                  ? " active"
                  : currentStep > step.idx
                  ? " completed"
                  : ""
              }`}
            >
              {/* eslint-disable-next-line */}
              <a
                onClick={(e) => {
                  e.preventDefault();
                  handleStep(step.idx);
                }}
              >
                <span>{step.idx}</span>
                {t(step.text)}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
