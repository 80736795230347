import { Card, Col, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import "./styles/ProductCardSkeleton.scss";

export const PromoSkeleton = () => {
  return (
    <>
      <Card.Header className="components-promos-header">
        <Skeleton height={200} />
      </Card.Header>
      <Card.Body>
        <div className="mb-3">
          <div className="d-flex flex-column justify-content-center align-items-center bg-brand-gray-light p-3 mb-3">
            <p
              className="text-xs text-brand-gray-dark text-uppercase m-0"
              style={{ letterSpacing: "1px" }}
            >
              <strong>title</strong>
            </p>
            <p className="mb-0 text-uppercase">totalItems</p>
          </div>
          <div>
            <Row>
              {Array(4)
                .fill("loading")
                .map((_, index) => (
                  <Col key={index} sm={6} xl={3} className="mb-4" role="button">
                    <Skeleton height={290} width={250} />
                  </Col>
                ))}
            </Row>
          </div>
        </div>
      </Card.Body>
    </>
  );
};
