import {
  Suspense,
  useCallback,
  useContext,
  Fragment,
  useState,
  useEffect,
} from "react";
import { Card, Image, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import slugify from "slugify";
import { BsHeart, BsHeartFill } from "react-icons/bs";
import PricesContext from "../../context/Prices";
import FavsContext from "../../context/Favs";
import useProduct from "../../hooks/useProduct";

import { ROUTES, PRICE_OPTIONS } from "../../global";
import { useLocation } from "react-router-dom";
import emptyImage from "../../assets/img/empty-image.png";
import "./styles/ProductSimpleCard.scss";
import { ProductSimpleCardSkeleton } from "./loaders";

export const ProductSimpleCard = ({ productSlug, horizontal = false }) => {
  const { favsReduced, addToFavs, removeFromFavs } = useContext(FavsContext);
  const [isLoading, setIsLoading] = useState(true);
  const { t, i18n } = useTranslation();
  const { price } = useContext(PricesContext);
  const product = useProduct(productSlug, {
    ...(price && { precio: PRICE_OPTIONS.client.value }),
    files: true,
    arbol: true,
    stock: true,
    precio: true,
  });

  const location = useLocation();

  useEffect(() => {
    if (product && product.Nombre) {
      setIsLoading(false);
    }
  }, [product]);

  const handleAddToFavs = (event) => {
    event.stopPropagation();
    event.preventDefault();

    addToFavs(product.Nombre);
  };

  const handleRemoveFromFavs = (event) => {
    event.stopPropagation();
    event.preventDefault();

    removeFromFavs(product.Nombre);
  };

  /**
   * @description Carga el badge que informa del Stock del producto.
   */
  const StockInfo = useCallback(() => {
    let variant = "success";
    let text = t("stock_inmediato");

    product.Stock =
      product.Stock === null || isNaN(product.Stock) ? 0 : product.Stock;
    if (product.StockEstado === 2) {
      variant = "warning";
      text = t("stock_bajo");
    }

    if (product.StockEstado === 3) {
      variant = "danger";
      text = t("sin_stock");
    }

    if (product.StockEstado === 4) {
      variant = "info";
      text = t("bajo_pedido");
    }

    return (
      <div
        className={`text-${variant} text-uppercase d-flex text-1 justify-content-start align-items-center gap-1`}
        style={{}}
      >
        <span
          className={`bg-${variant} rounded-circle`}
          style={{ width: "12px", height: "12px", display: "inline-block" }}
        >
          &nbsp;
        </span>{" "}
        {text}
      </div>
    );
  }, [product, t]);

  return (
    <Card
      className={`components-ui-product-simple-card ${
        horizontal ? "horizontal" : ""
      }`.trim()}
    >
      {isLoading ? (
        <ProductSimpleCardSkeleton horizontal />
      ) : (
        <Suspense fallback={<ProductSimpleCardSkeleton horizontal />}>
          <Card.Body
            as={Link}
            to={`/${ROUTES[i18n.resolvedLanguage].products}/${slugify(
              product.Nombre,
              { lower: true },
            )}`}
          >
            <div className="image-frame">
              <div className="image-frame-wrapper">
                <Image
                  src={product.ImagenWeb_small ?? emptyImage}
                  alt={product.Nombre}
                  loading="lazy"
                  decoding="async"
                  className="d-block mx-auto"
                  fluid
                />
              </div>
            </div>
            <div className="info">
              <small className="ecommerce-sidebar-link text-brand-gray-dark text-color-hover-primary text-uppercase">
                {product.Arbol.Familia.Nombre},{" "}
                {product.Arbol.SubFamilia.Nombre}
              </small>

              <h4 className="text-4 line-height-2 ecommerce-sidebar-link text-color-primary fw-bold my-1">
                {product.Nombre}
              </h4>
              {favsReduced.includes(product.Nombre) ? (
                <span
                  className="text-brand-primary float-end px-2"
                  role="button"
                  onClick={handleRemoveFromFavs}
                >
                  <BsHeartFill />
                </span>
              ) : (
                <span
                  className="text-brand-gray-dark float-end px-2"
                  role="button"
                  onClick={handleAddToFavs}
                >
                  <BsHeart />
                </span>
              )}
              <div className="d-flex flex-row gap-2 flex-wrap">
                {[...new Set(Object.values(product.Tags ?? {}))]
                  .filter((tag) => tag)
                  .map((tag) => {
                    return (
                      <small key={slugify(tag)} className="d-inline-block">
                        {tag}
                      </small>
                    );
                  })}
              </div>
              <StockInfo />
              {location.pathname !== "/" ? (
                ""
              ) : (
                <>
                  <div className="d-flex align-items-center gap-2 mt-3">
                    {(price && product.Precios.Neto.Importe > 0) ||
                    product.PrecioFinDeSerie > 0 ? (
                      <Fragment>
                        <Badge
                          bg="brand-gray"
                          className="badge-ecommerce d-block my-0 text-uppercase"
                        >
                          {t("neto")}
                        </Badge>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <Badge
                          bg="danger"
                          className="badge-ecommerce d-block my-0 text-uppercase"
                        >
                          {t("precio_a_consultar")}
                        </Badge>
                      </Fragment>
                    )}
                  </div>

                  <div className="d-flex align-items-center gap-2">
                    {product.Precios && product.Precios.Neto.Importe > 0 ? (
                      <div className="mt-2">
                        <span className="fw-bold d-inline-block h4-dashboard my-0 text-dark">
                          {new Intl.NumberFormat(i18n.resolvedLanguage, {
                            style: "currency",
                            currency:
                              product.Precios.Neto.Divisa === ""
                                ? "EUR"
                                : product.Precios.Neto.Divisa,
                          }).format(parseFloat(product.Precios.Neto.Importe))}
                        </span>
                        {product.Precios.PVR.Importe > 0 ? (
                          <span className="fw-bold d-inline-block h6-listado my-0 ml-3 text-mutted text-decoration-line-through">
                            {new Intl.NumberFormat(i18n.resolvedLanguage, {
                              style: "currency",
                              currency:
                                product.Precios.PVR.Divisa === ""
                                  ? "EUR"
                                  : product.Precios.PVR.Divisa,
                            }).format(parseFloat(product.Precios.PVR.Importe))}
                          </span>
                        ) : (
                          <span className="fw-bold d-inline-block h4-listado my-0 text-dark">
                            <p></p>{" "}
                            {/* no eliminar, para que no se descuadre el cajetín */}
                          </span>
                        )}
                      </div>
                    ) : (
                      <p></p>
                    )}
                  </div>
                </>
              )}
            </div>
          </Card.Body>
        </Suspense>
      )}
    </Card>
  );
};
