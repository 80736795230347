import { Button, Card, InputGroup } from "react-bootstrap";
import { Wrapper } from "../Wrapper";

import "./styles/ProductCardSkeleton.scss";

export const ProductCardSkeleton = () => {
  return (
    <Wrapper
      Component={Card}
      className="components-ui-loaders-product-card-skeleton rounded-0 h-100"
    >
      <Card.Body className="p-0 d-flex flex-column">
        <div className="image-frame border border-brand-gray-light">
          <div className="image-frame-wrapper">
            <div className="ratio ratio-1x1 shine"></div>
          </div>
        </div>
        <div className="bg-brand-gray-light p-3 flex-grow-1">
          <div className="mb-1 w-50 shine" style={{ height: "14px" }}></div>
          <div
            className="mt-0 mb-2 w-100 shine"
            style={{ height: "18px" }}
          ></div>
          <div className="mb-3 w-25 shine" style={{ height: "12px" }}></div>
          <div className="mb-1 w-100 shine" style={{ height: "12px" }}></div>
          <div className="mb-1 w-100 shine" style={{ height: "12px" }}></div>
          <div className="mb-1 w-50 shine" style={{ height: "12px" }}></div>
        </div>
      </Card.Body>
      <Card.Footer className="p-0 border-0">
        <div className="d-flex align-items-center justify-content-between gap-2 mt-3">
          <div className="my-0 w-50 shine" style={{ height: "26px" }}></div>
          <div className="my-0 w-50 shine" style={{ height: "14px" }}></div>
        </div>
        <div className="footer-actions mt-3">
          <div>
            <InputGroup>
              <Button variant="outline-secondary" className="shine" disabled>
                -
              </Button>
              <div className="shine flex-grow-1"></div>
              <Button variant="outline-secondary" className="shine" disabled>
                +
              </Button>
            </InputGroup>
          </div>
          <div>
            <Button
              variant="outline-secondary"
              className="text-transparent shine w-100"
              disabled
            >
              &nbsp;
            </Button>
          </div>
        </div>
      </Card.Footer>
    </Wrapper>
  );
};
