import { API_URL, DEFAULT_REQUEST_CONFIG } from "../global";
import { constructInitRequestConfig, defaultFetcher } from "../helpers";

/**
 * @description Autentica al usuario en la aplicación de Fonestar.
 * @param {string} token Token de seguridad.
 * @param {string} lng Lenguage del recurso solicitado.
 * @param {String} username Nombre de usuario.
 * @param {String} password Contraseña.
 * @param {Boolean} [remember=false] Determina si la sesión debe durar más.
 * @returns {Object}
 */
export async function login(token, lng, username, password, remember = false) {
  return defaultFetcher(
    `${API_URL}/login`,
    constructInitRequestConfig(
      { ...DEFAULT_REQUEST_CONFIG, method: "POST" },
      {
        lng: lng,
        fstoken: token,
        datos: true,
      },
      { fs_user: username, fs_key: password }
    )
  ).then((response) => {
    return {
      value: response["api-token"],
      expirationDate: response["expiration-date"],
    };
  });
}
