import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import "./styles/FeaturedCard.scss";

export const FeaturedCard = ({
  title,
  amount,
  to,
  variant = "fonestar",
  linkTextKey = "ver",
  Icon = null,
  code = null,
}) => {
  const { t } = useTranslation();

  return (
    <section
      className={`card card-featured-left card-featured-${variant} mb-3`}
    >
      <div className="card-body">
        <div className="widget-summary">
          {Icon && (
            <div className="widget-summary-col widget-summary-col-icon">
              <div
                className={`summary-icon bg-${variant} d-flex justify-content-center align-items-center`}
              >
                <Icon />
              </div>
            </div>
          )}
          <div className="widget-summary-col">
            <div className="summary">
              <h4
                className={`title text-uppercase tracking-widest text-${variant}`}
              >
                {title}
              </h4>
              <div className="info">
                <strong className={`display-6 text-${variant}`}>
                  {amount}
                </strong>
              </div>
            </div>
            <div className="summary-footer">
              <Link
                to={to}
                state={{ code }}
                className={`text-2 text-decoration-none text-${variant}`}
              >
                + {t(linkTextKey)}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
