import { useState } from "react";
import { Toast } from "react-bootstrap";
import { IoWarningOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { ROUTES } from "../../global";

export const ToastErrorImport = ({ responseError }) => {
  const [showToast, setShowToast] = useState(true);
  const { i18n } = useTranslation();
  const handleCloseToast = () => {
    setShowToast(!showToast);
    localStorage.removeItem("errorProduct");
  };

  return (
    <Toast
      bg="danger"
      style={{ width: "433px", display: "block", margin: "auto" }}
      show={showToast}
      onClose={handleCloseToast}
    >
      <Toast.Header>
        <i style={{ marginRight: "10px" }}>
          <IoWarningOutline size="1.15rem" aria-hidden="true" />
        </i>
        <strong className="me-auto">ERROR DE IMPORTACIÓN</strong>
      </Toast.Header>
      {responseError?.length > 0 && typeof responseError === "object" ? (
        <Toast.Body>
          <p className="text-white">
            Error de formato en el archivo. Sigue las instrucciones en nuestro{" "}
            <strong>
              <a
                className="text-white"
                href={`/${ROUTES[i18n.resolvedLanguage].import}`}
              >
                tutorial para importar productos
              </a>
            </strong>
          </p>
        </Toast.Body>
      ) : (
        <Toast.Body>
          <p className="text-white">
            Ha surgido un problema a la hora de importar tu archivo. Sigue las
            instrucciones en nuestro{" "}
            <strong>
              <a
                className="text-white"
                href={`/${ROUTES[i18n.resolvedLanguage].import}`}
              >
                tutorial para importar productos
              </a>
            </strong>
          </p>
          <p className="text-white">{responseError}</p>
        </Toast.Body>
      )}
    </Toast>
  );
};
