// + info acerca de esto: https://www.createit.com/blog/i18next-react-links-inside-translations/
export const LinkText = (props) => {
  return (
    <>
      {props.style ? (
        <a
          style={{ color: props.style }}
          href={props.to || "#"}
          title={props.title || ""}
        >
          {props.children}
        </a>
      ) : (
        <>
          <a href={props.to || "#"} title={props.title || ""}>
            {props.children}
          </a>
        </>
      )}
    </>
  );
};
