import { useTranslation } from "react-i18next";
import { Spinner } from "react-bootstrap";

export const PageLoader = ({ text = null }) => {
  const { t } = useTranslation();

  return (
    <div className="w-100 d-flex align-items-center justify-content-between py-4">
      <strong>{text ?? t("cargando")}...</strong>
      <Spinner animation="border" role="status" />
    </div>
  );
};
