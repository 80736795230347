import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const RMAStep1Skeleton = () => {
  return (
    <>
      <tr className="components-rmas-products-selection-table-row">
        <td>
          <Skeleton width={20} height={20} />
        </td>
        <td>
          <p className="text-4 line-height-2 text-primary fw-bold m-0">
            <Skeleton />
          </p>
          <small className="text-brand-gray-dark m-0">
            <Skeleton />
          </small>
        </td>
        <td>
          <Skeleton />
        </td>
        <td>
          <Skeleton />
        </td>
        <td>
          <Skeleton />
        </td>
        <td className="quantity-cell">
          <section className="d-flex align-items-center gap-1">
            <Skeleton />
            <span>
              <Skeleton />
            </span>
          </section>
        </td>
        <td>
          <Skeleton />
        </td>
        <td>
          <Skeleton />
        </td>
      </tr>
    </>
  );
};
