import "./styles/ExtraTitle.scss";

export const ExtraTitle = ({ title, subtitle, Icon, Image = "" }) => {
  return (
    <div className="components-ui-extra-title title-with-marker">
      <div>
        <div>{Image ? <img src={Image} alt="" /> : <Icon size={"3rem"} />}</div>
        <div>
          <strong
            className="h6 mt-0 mb-2 d-block"
            style={{ "text-align": "left" }}
          >
            {title}
          </strong>
          <p className="tracking-widest uppercase">{subtitle}</p>
        </div>
      </div>
    </div>
  );
};
