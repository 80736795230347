import { createContext, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { addItem, removeItem } from "../api/favs";
import useAuth from "../hooks/useAuth";
import useFavs from "../hooks/useFavs";
import NotificationsContext from "./Notifications";

/**
 * Este contexto se utiliza para el refresco de los widgets que tengan que ver con los productos favoritos, al estar
 * todo manejado por base de datos y de forma asícrona, es necesario mandar una señal entre componentes para que éstos
 * se refresquen y actualicen el UI.
 *
 * La forma más sencilla es crear un contexto que contenga la fecha de actualización de lso favoritos mientras se
 * utiliza la aplicación y que dicha fecha se actualice al añadir o quitar elementos favoritos.
 *
 * De la misma manera se gestionan los elementos del carrito.
 */

const FavsContext = createContext({
  favs: [],
  favsReduced: [],
  addToFavs: () => {},
  removeFromFavs: () => {},
});

const FavsProvider = (props) => {
  const { token } = useAuth();
  const { t, i18n } = useTranslation();
  const { addNotification } = useContext(NotificationsContext);

  const [favsUpdated, setFavsUpdated] = useState(null);
  const favs = useFavs(favsUpdated);

  const favsReduced = useMemo(() => {
    return favs.reduce((acc, current) => {
      return [...new Set([...acc, ...current.Familia._items])];
    }, []);
  }, [favs]);

  const addToFavs = async (product) => {
    await addItem(i18n.resolvedLanguage, token.value, product)
      .then(() => {
        addNotification(
          t("anadido_X_a_favoritos", { product: product }),
          `${t("se_ha_anadido_el_producto_X_a_tus_favoritos", {
            product: product,
          })}.`,
          "success"
        );

        setFavsUpdated(new Date().toISOString());
      })
      .catch((error) => {
        addNotification(
          t("error_al_anadir_el_producto"),
          error.info["status-details"] ??
            `${t("no_se_ha_podido_anadir_el_producto_X_a_tus_favoritos", {
              product: product,
            })}.`,
          "danger"
        );
      });
  };

  const removeFromFavs = async (product) => {
    await removeItem(i18n.resolvedLanguage, token.value, product)
      .then(() => {
        addNotification(
          t("eliminado_X_de_favoritos", { product: product }),
          `${t("se_ha_eliminado_el_producto_X_de_tus_favoritos", {
            product: product,
          })}.`,
          "success"
        );

        setFavsUpdated(new Date().toISOString());
      })
      .catch((error) => {
        addNotification(
          t("error_al_quitar_el_producto"),
          error.info["status-details"] ??
            `${t("no_se_ha_podido_quitar_el_producto_X_de_tus_favoritos", {
              product: product,
            })}.`,
          "danger"
        );
      });
  };

  return (
    <FavsContext.Provider
      value={{
        favs,
        favsReduced,
        addToFavs,
        removeFromFavs,
      }}
    >
      {props.children}
    </FavsContext.Provider>
  );
};

export { FavsProvider };
export default FavsContext;
