import { createContext, useState } from "react";
import { RMA_STATUS } from "../global";
import useRmas from "../hooks/useRmas";
import useStats from "../hooks/useStats";

const RmaContext = createContext({
  stats: {},
  rmas: [],
  mutate: () => {},
});

const RmaProvider = (props) => {
  const [rmasUpdated, setRmasUpdated] = useState(null);
  const { RMAS: stats } = useStats({ RMAS: true }, false, rmasUpdated);
  const { data: rmas, mutate } = useRmas({
    Cuantos: 9999,
    PagInicio: 1,
    Estados: RMA_STATUS.saved,
  });

  const update = () => {
    setRmasUpdated(new Date().toISOString());
    mutate();
  };

  return (
    <RmaContext.Provider
      value={{
        stats,
        rmas,
        update,
      }}
    >
      {props.children}
    </RmaContext.Provider>
  );
};

export { RmaProvider };
export default RmaContext;
