import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import { isAfter, parseISO } from "date-fns";
import { useLocalStorage } from "usehooks-ts";
import { useTranslation } from "react-i18next";

import { currentUser, login as loginApi } from "../api";

import { EMPTY_TOKEN, ROUTES } from "../global";
import { extractItemsFromApiRequest, isPublicRoute } from "../helpers";

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const { i18n } = useTranslation();

  /*
   * La autentición tiene dos parámetros:
   *
   * - `value`: String para las peticiones que debe viajar en la cabecera como
   *     `fstoken`.
   * - `expirationDate: Fecha de expiración del token en cuestión.
   */
  const [token, setToken] = useLocalStorage("token", EMPTY_TOKEN);

  /*
   * Datos del usuario para que no sea necesario llamar a la API en todo
   * momento, ya que aparece en sitios comunes como la cabecera.
   */
  const [user, setUser] = useState();

  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingInitial, setLoadingInitial] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();

  // Si la página cambia, resetea el estado del `error`.
  useEffect(() => {
    if (error) {
      setError(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  // Check if there is a currently active session when the provider is mounted for the first time.
  //
  // If there is an error, it means there is no session.
  //
  // Finally, just signal the component that the initial load is over.
  useEffect(() => {
    handleUser().finally(() => setLoadingInitial(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token.value, i18n.resolvedLanguage]);

  const handleUser = async () => {
    return await currentUser(token.value, i18n.resolvedLanguage, {
      datos: true,
    })
      .then((response) => {
        if (response.login?.status !== "Logged in") {
          if (!isPublicRoute(location.pathname.replace(/\//, ""))) {
            logout();
          }

          return;
        }

        const user = extractItemsFromApiRequest(response);
        if (user) {
          setUser(user);
        }
      })
      .catch(() => {
        if (!isPublicRoute(location.pathname.replace(/\//, ""))) {
          logout();
        }
      });
  };

  /**
   * Trata de iniciar sesión en la aplicación.
   *
   * @param {String} username Nombre de usuario.
   * @param {String} password Contraseña.
   * @param {Boolean} remember Si la sesión debe ser más larga o no.
   * @param {String} redirectTo Lugar al que redirigir si se loguea con éxito.
   */
  const login = (username, password, remember, redirectTo = "/") => {
    setLoading(true);

    loginApi(token.value, i18n.resolvedLanguage, username, password, remember)
      .then((response) => {
        setToken(response);
        navigate(redirectTo, { replace: true });
      })
      .catch((error) => {
        setToken(EMPTY_TOKEN);
        setError(error);
      })
      .finally(() => setLoading(false));
  };

  /**
   * Cierra la sesión.
   */
  const logout = () => {
    setToken(EMPTY_TOKEN);
    setUser(undefined);
    navigate(ROUTES[i18n.resolvedLanguage].login, {
      replace: true,
      state: { from: location.pathname },
    });
  };

  /**
   * Determina si el usuario está logueado:
   *
   * - Tiene un token establecido.
   * - El token no ha expirado.
   *
   * @return {Boolean} Si el usuario está autenticado o no.
   */
  const isLogged = () => {
    if (!token.value) {
      return false;
    }

    // if (isAfter(new Date(), new Date(parseISO(token.expirationDate)))) {
    //   return false;
    // }

    return true;
  };

  /**
   * @description Determina si se puede acceder a una sección o lugar.
   * @param {string} section Sección a la que se intenta acceder.
   * @returns {boolean}
   */
  const canAccess = (section) => {
    return user?.Permisos?.map((permission) =>
      permission.toLowerCase(),
    ).includes(section.toLowerCase());
  };

  /**
   * @description Determina si un usuario tiene acceso a la sección de Fonestar Fidelity.
   * @returns {boolean}
   */
  const canAccessFidelity = () => {
    return user?.Segmentacion === "EXPORT";
  };

  /**
   * Hace que el provider se actualice sólo en caso necesario.
   * Sólo tiene que forzar el re-renderizado cuando el usuario, el token, la
   * carga o el error cambian.
   *
   * Siempre que el valor pasado a un provider cambia, todo el árbol bajo dicho
   * provider se vuelve a renderizar, lo cual puede ser muy costoso. Para
   * mantener un buen rendimiento utilizamos este `useMemo`.
   */
  const memoedValue = useMemo(
    () => ({
      user,
      token,
      loading,
      error,
      login,
      logout,
      isLogged,
      canAccess,
      canAccessFidelity,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user, token, loading, error],
  );

  return (
    <AuthContext.Provider value={memoedValue}>
      {!loadingInitial && children}
    </AuthContext.Provider>
  );
}

export default function useAuth() {
  return useContext(AuthContext);
}
