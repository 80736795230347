import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const SustitutoToastSkeleton = () => {
  return (
    <div>
      <Skeleton width={350} height={150} />
    </div>
  );
};
