import { createContext, useEffect, useState } from "react";
import { PRICE_OPTIONS } from "../global";

/**
 * Este contexto se utiliza para el control de los precios, la API estima el precio de los productos dependiendo si se
 * le está solicitando el "pvr" o el precio al "cliente", pero también existe la posibilidad de no mostrar los precios
 * en el front si así se estima.
 *
 * @see src/global.js::PRICE_OPTIONS
 */

const PricesContext = createContext({
  price: "",
  priceLabel: "",
  setPrice: () => {},
});

const PricesProvider = (props) => {
  const [price, setPrice] = useState(PRICE_OPTIONS.client.value);
  const [priceLabel, setPriceLabel] = useState(PRICE_OPTIONS.client.label);

  useEffect(() => {
    const currentPrice = Object.values(PRICE_OPTIONS).find(
      ({ value }) => price === value
    );

    setPriceLabel(currentPrice.label);
  }, [price]);

  return (
    <PricesContext.Provider value={{ price, priceLabel, setPrice }}>
      {props.children}
    </PricesContext.Provider>
  );
};

export { PricesProvider };
export default PricesContext;
