import { useTranslation } from "react-i18next";
import useSWR from "swr";

import useAuth from "./useAuth";
import {
  constructInitRequestConfig,
  extractItemsFromApiRequest,
} from "../helpers";
import { API_URL, DEFAULT_REQUEST_CONFIG } from "../global";

const useStats = (headers = {}, expectArrayChildren = true, updated = null) => {
  const { i18n } = useTranslation();
  const { token } = useAuth();
  const { data } = useSWR([
    `${API_URL}/stats`,
    constructInitRequestConfig(DEFAULT_REQUEST_CONFIG, {
      lng: i18n.resolvedLanguage,
      fstoken: token.value,
      ...headers,
    }),
    updated,
  ]);

  return extractItemsFromApiRequest(data, expectArrayChildren);
};

export default useStats;
