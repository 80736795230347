import { memo, useEffect, useState } from "react";
import { Pagination as PaginationComponent } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import "./styles/Pagination.scss";

/**
 * @description Retorna las páginas a pintar en el paginador.
 * @param {number} totalPages Páginas totales.
 * @param {number} currentPage Página actual.
 * @returns {Array}
 * @example - Páginas totales <= 3:
 * --------------
 * | 1 | 2* | 3 |
 * --------------
 * @example - Página actual <= 2:
 * -------------------------
 * | 1 | 2 | 3 | ... | 210 |
 * -------------------------
 * @example - Páginas totales - página actual < 2:
 * -------------------------
 * | ... | 208 | 209 | 210 |
 * -------------------------
 * @example - Página central:
 * ------------------------------
 * | ... | 99 | 100 | 101 | ... |
 * ------------------------------
 */
const getPaginationPagesToRender = (totalPages, currentPage) => {
  if (totalPages <= 1) {
    return [];
  }

  if (totalPages <= 3) {
    return Array.from({ length: totalPages }, (_, index) => index + 1);
  }

  if (currentPage <= 2) {
    return [1, 2, 3, "", totalPages];
  }

  if (totalPages - currentPage < 2) {
    return [1, "", totalPages - 2, totalPages - 1, totalPages];
  }

  return [1, "", currentPage - 1, currentPage, currentPage + 1, "", totalPages];
};

export const Pagination = memo(function Pagination({
  totalPages,
  currentPage,
  pageClicked = () => {},
}) {
  const { t } = useTranslation();
  const [pageArray, setPageArray] = useState(
    getPaginationPagesToRender(totalPages, currentPage)
  );

  useEffect(() => {
    setPageArray(getPaginationPagesToRender(totalPages, currentPage));
  }, [totalPages, currentPage]);

  return (
    <nav
      aria-label={t("navegacion_de_la_tabla")}
      className="components-ui-pagination"
    >
      <PaginationComponent className="justify-content-center justify-content-lg-start gap-1 mb-0">
        {Array.isArray(pageArray) &&
          pageArray.map((page, index) => {
            const toReturn = [];

            if (index === 0 && currentPage !== 1) {
              toReturn.push(
                <PaginationComponent.Prev
                  key="prevpage"
                  onClick={
                    currentPage === 1
                      ? () => {}
                      : () => {
                          pageClicked(currentPage - 1);
                        }
                  }
                />
              );
            }

            if (page === "") {
              toReturn.push(<PaginationComponent.Ellipsis key={index} />);
            } else {
              toReturn.push(
                <PaginationComponent.Item
                  key={index}
                  active={currentPage === page ? true : false}
                  onClick={
                    currentPage === page
                      ? () => {}
                      : () => {
                          pageClicked(page);
                        }
                  }
                >
                  {page}
                </PaginationComponent.Item>
              );
            }

            if (index === pageArray.length - 1 && currentPage !== totalPages) {
              toReturn.push(
                <PaginationComponent.Next
                  key="nextpage"
                  onClick={
                    currentPage === page
                      ? () => {}
                      : () => {
                          pageClicked(currentPage + 1);
                        }
                  }
                />
              );
            }

            return toReturn;
          })}
      </PaginationComponent>
    </nav>
  );
});
