import Skeleton from "react-loading-skeleton";
import "./styles/ProductCardSkeleton.scss";

export const OrderSkeleton = () => {
  return Array(12)
    .fill("loading")
    .map((_, index) => (
      <tr key={index}>
        <td className="text-primary fw-bold">
          <Skeleton />
        </td>
        <td>
          <Skeleton width={50} />
        </td>
        <td>
          <Skeleton width={50} />
        </td>
        <td>
          <Skeleton />
        </td>
        <td>
          <Skeleton />
        </td>
        <td>
          <Skeleton />
        </td>
      </tr>
    ));
};
