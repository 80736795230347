import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Image } from "react-bootstrap";
import { useMediaQuery } from "usehooks-ts";
import SimpleBar from "simplebar-react";
import { SideBySideMagnifier } from "@vanyapr/react-image-magnifiers";
import FsLightbox from "fslightbox-react";

import { Wrapper } from "../ui";
import { BREAKPOINTS } from "../../global";

//import "./styles/Carousel.scss";
import "./styles/css/Gallery.css";
import "simplebar-react/dist/simplebar.min.css";

const Gallery = ({ images, imagesBig, imagesMedium }) => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);

  const isGreatherThanXL = useMediaQuery(`(min-width: ${BREAKPOINTS.xl}px)`);
  const [toggler, setToggler] = useState(false);
  const handleActiveIndex = (idx) => {
    setActiveIndex(idx);
  };

  const slide = [imagesBig[activeIndex], ...imagesBig];

  return (
    <Wrapper className="components-ui-carousel">
      <div
        onClick={() => setToggler(!toggler)}
        className="input-position"
        id="leave"
      >
        <SideBySideMagnifier
          className="parent"
          imageSrc={`${imagesBig[activeIndex]}`}
          largeImageSrc={images[activeIndex]}
          overlayBoxOpacity={0}
          overlayBoxColor="#000"
          overlayBackgroundColor="#000"
          overlayOpacity={0.3}
          switchSides={false}
          zoomPosition="right"
          fillAvailableSpace={true}
          fillAlignTop={true}
          fillGapTop={20}
          fillGapRight={20}
          fillGapBottom={20}
          fillGapLeft={20}
          zoomContainerBorder="0px solid #ccc"
          zoomContainerBoxShadow="0 8px 8px rgba(0,0,0,.5)"
        />
      </div>
      {isGreatherThanXL && (
        <SimpleBar className="mw-100">
          <div className="carousel-thumbnails">
            {imagesMedium.map((image, idx) => {
              return (
                <figure
                  key={idx}
                  role="button"
                  className={activeIndex === idx ? "active" : ""}
                  onClick={() => handleActiveIndex(idx)}
                >
                  <Image
                    src={image}
                    alt={`${t("miniatura")} ${idx}`}
                    loading="lazy"
                    decoding="async"
                  />
                </figure>
              );
            })}
          </div>
        </SimpleBar>
      )}
      <FsLightbox
        toggler={toggler}
        sources={slide.map((image) => image)}
        type="image"
      />
    </Wrapper>
  );
};

export default Gallery;
