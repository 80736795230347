import { Card } from "react-bootstrap";

import "./styles/ProductSimpleCardSkeleton.scss";

export const ProductSimpleCardSkeleton = ({ horizontal = false }) => {
  return (
    <Card
      className={`components-ui-loaders-product-simple-card-skeleton ${
        horizontal ? "horizontal" : ""
      }`.trim()}
    >
      <Card.Body>
        <div className="image-frame">
          <div className="image-frame-wrapper">
            <div className="ratio ratio-1x1 w-100 h-100 shine"></div>
          </div>
        </div>
        <div className="info pt-1">
          <div className="mb-1 w-50 shine" style={{ height: "14px" }}></div>
          <div
            className="mt-0 mb-2 w-100 shine"
            style={{ height: "18px" }}
          ></div>
          <div className="mb-3 w-25 shine" style={{ height: "12px" }}></div>
          <div className="mb-1 w-50 shine" style={{ height: "12px" }}></div>
        </div>
      </Card.Body>
    </Card>
  );
};
