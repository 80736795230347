import { Link } from "react-router-dom";
import { BiHomeAlt } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import { useMediaQuery, useOnClickOutside } from "usehooks-ts";
import { Fragment, useRef, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";

import { BREAKPOINTS } from "../../global";

export const Breadcrumbs = ({ header, sections = [] }) => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [show, setShow] = useState(false);
  const isMinWidth1200 = useMediaQuery(`(min-width: ${BREAKPOINTS.xl}px)`);

  useOnClickOutside(ref, () => {
    setShow(false);
  });

  const restSections = sections.length > 1 ? [...sections].slice(0, -1) : [];
  const lastSection = sections.length > 0 ? sections.at(-1) : null;

  return (
    <header className="page-header">
      <h2 data-testid="welcome-title">{t(`${header}`)}</h2>

      <div className="right-wrapper text-end">
        <ol className="breadcrumbs pe-3">
          <li>
            <Link to="/" aria-label={t("panel_de_control")}>
              <BiHomeAlt size="1.4rem" aria-hidden="true" />
            </Link>
          </li>

          {isMinWidth1200 ? (
            restSections.map(([link, section], idx) => {
              return (
                <li key={idx}>
                  <Link to={link}>{section}</Link>
                </li>
              );
            })
          ) : (
            <Fragment>
              {restSections.length > 0 && (
                <li>
                  <OverlayTrigger
                    trigger="click"
                    placement="bottom"
                    show={show}
                    overlay={
                      <Popover>
                        <Popover.Body ref={ref}>
                          <ol className="m-0">
                            {restSections.map(([link, section], idx) => {
                              return (
                                <li key={idx}>
                                  <Link to={link}>{section}</Link>
                                </li>
                              );
                            })}
                          </ol>
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <a
                      href="/"
                      variant="link"
                      onClick={(e) => {
                        e.preventDefault();
                        setShow(!show);
                      }}
                    >
                      ...
                    </a>
                  </OverlayTrigger>
                </li>
              )}
            </Fragment>
          )}

          {lastSection && (
            <li>
              <Link to={lastSection[0]}>{lastSection[1]}</Link>
            </li>
          )}
        </ol>
      </div>
    </header>
  );
};
