/**
 * Determina si se trata de un entorno de desarrollo o no.
 */
export const IS_DEVELOPMENT =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development";

/**
 * URL genérica de la API de Fonestar.
 */
export const API_URL = `${process.env.REACT_APP_API_BASE_URL}/v1`;

/**
 * Identificador de la categoría especial "Sistemas y Control".
 */
export const SISTEMAS_Y_CONTROL_ID = 19;

/**
 * Configuración por defecto de una llamada a la API de Fonestar.
 */
export const DEFAULT_REQUEST_CONFIG = {
  method: "GET",
  credentials: "include",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-API-KEY": process.env.REACT_APP_X_API_KEY,
    // debug: IS_DEVELOPMENT ? 1 : 0,
  },
};

/**
 * Valor por defecto del token de autenticación.
 */
export const EMPTY_TOKEN = {
  value: null,
  expirationDate: null,
};

/**
 * Colores de marca de Fonestar.
 */
export const BRAND_COLORS = {
  red: "#db052c",
  gray: "#94969d",
  grayLight: "#e1e1e6",
  grayDark: "#5a5c63",
};

/**
 * Puntos de ruptura del proyecto.
 */
export const BREAKPOINTS = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
};

/**
 * Idiomas del proyecto.
 */
export const LANGUAGES_AVAILABLES = {
  en: "en",
  es: "es",
  fr: "fr",
  pt: "pt",
};

/**
 * Origen de los formularios para enviar en el POST.
 */
export const FORMS_ORIGIN = "Área de cliente";

/**
 * Identificador del formulario de contacto del WordPress.
 */
export const CONTACT_FORM_WP_ID = {
  en: "clientes_consultas",
  es: "clientes_consultas",
  fr: "clientes_consultas",
  pt: "clientes_consultas",
};

/**
 * Identificador del formulario de diseña tu proyecto del WordPress, ahora se llama nuevo presupuesto
 */
export const NEW_BUDGET_FORM_WP_ID = {
  en: "clientes_cotizaciones",
  es: "clientes_cotizaciones",
  fr: "clientes_cotizaciones",
  pt: "clientes_cotizaciones",
};

/**
 * Identificador del formulario de servicios web del WordPress.
 */
export const WEB_SERVICES_FORM_WP_ID = {
  en: "clientes_ws_export",
  es: "clientes_ws",
  fr: "clientes_ws",
  pt: "clientes_ws_pt",
};

/**
 * Identificador del formulario de presupuestos (cotizaciones) del WordPress.
 * Es el mismo formulario para todos los idiomas.
 */
export const FORM_ACCEPT_BUDGET_WP_ID = {
  en: 17304,
  es: 17304,
  fr: 17304,
  pt: 17304,
};

/**
 * Identificador del formulario de servicios web del WordPress.
 
export const FORM_NEW_USER_WP_ID = {
  en: 18187, //(Area de cliente) Solicitud de registro.
  es: 18187,
  fr: 18187,
  pt: 18187,
};
*/

/**
 * Cuenta a mostrar en el mensaje de error cuando un pedido halla fallado.
 */
export const ORDER_FAILED_DISPLAY_MAIL_TO = {
  en: "export@fonestar.es",
  es: "consultas@fonestar.es",
  fr: "france@fonestar.fr",
  pt: "portugal@fonestar.pt",
};

/**
 * Tipos de RMA existentes.
 */
export const RMA_TYPES = [
  { code: "0", i18nKey: "devolucion" },
  { code: "1", i18nKey: "reparacion" },
];

/**
 * Posibles estados de un RMA.
 */
export const RMA_STATUS = {
  onHold: 1,
  inProgress: 2,
  completed: 3,
  saved: 4,
};

/**
 * Posibles estados de los pedidos.
 */
export const ORDERS_STATUS = {
  onHold: 1,
  inProgress: 2,
  send: 3,
  completed: 4,
};

/**
 * Posibles estados de los presupuestos.
 */
export const BUDGETS_STATUS = {
  valid: 1,
  expiredOrCancelled: 2,
  accepted: 3,
  onHold: 4,
};

/**
 * Código de factura (virtual) para los productos de RMA sin factura asociada.
 */
export const RMA_UNBILLED_ID = "n/a";

/**
 * Identificador del motivo "Otro motivo" cuando el tipo de RMA es "Devolución".
 */
export const OTHER_REASON_RETURN_ID = "5";

/**
 * Identificador del motivo "Otro motivo" cuando el tipo de RMA es "Reparación".
 */
export const OTHER_REASON_REPAIR_ID = "13";

/**
 * Valores para los select de la paginación.
 */
export const DEFAULT_PAGINATION_OPTIONS = [
  { value: 12, label: 12 },
  { value: 24, label: 24 },
  { value: 36, label: 36 },
  { value: 999999, label: "todos" },
];

/**
 * Opciones que puede adquirir la aplicación con los precios.
 *
 * @see src/context/Prices.js
 */
export const PRICE_OPTIONS = {
  pvr: { label: "pvr", value: "pvr" },
  client: { label: "neto", value: "cliente" },
  hidden: { label: "no", value: null },
};

/**
 * Tipos de resultados que puede albergar el buscador.
 */
export const SEARCH_RESULT_TYPES = {
  content: "content",
  product: "product",
  category: "category",
};

/**
 * Códigos de los tipos de ofertas de Fueras de Serie.
 */
export const FUERAS_DE_SERIE_IDS = {
  promos: 1,
  superPromos: 2,
  megaPromos: 3,
  agujas: 4,
};

/**
 * Códigos de los tipos de ofertas del Outlet.
 */
export const OUTLET_IDS = {
  discontinued: "OCA001", // ANTIGUO / DESCONTINUADO
  used: "OCA002", // GOLPE / RAYON / DESCOLORIDO / USADO
  packing: "OCA003", // EMBALAJE MAL
};

/**
 * Nombre de las secciones retornadas por los accesos de la API.
 */
export const SECTION_PERMISSIONS = {
  financial: "FINANCIERO",
  orders: "PEDIDOS",
  rmas: "RMAS",
};

/**
 * Datos de FONESTAR como empresa.
 */
export const FONESTAR = {
  companyName: "FONESTAR SISTEMAS, S.A.",
  shortName: "FONESTAR",
  document: "A28780443",
  address: "D. Ramón de la Cruz, 64, 28001 Madrid",
  registry: {
    place: "Madrid",
    sheet: "M 156950",
    folio: "64",
    volume: "9770",
    section: "1",
  },
  phone: "942254555",
  email: "web@fonestar.com",
  domain: "www.fonestar.com",
  offices: {
    es: [
      {
        scopes: [
          "Cantabria",
          "Castilla y León",
          "La Rioja",
          "Navarra",
          "País Vasco",
        ],
        address_line_01: "Polígono Trascueto",
        address_line_02: null,
        zip: "39600",
        location: "Revilla de Camargo",
        state: "Cantabria",
        phone: "+34 942 254 555",
        email: "consultas@fonestar.es",
        main: true,
      },
      {
        scopes: ["Asturias", "Galicia"],
        address_line_01: "Avda. Montserrat, 4-18",
        address_line_02: "Urb. Los Arces, bloque I, local 3",
        zip: "15009",
        location: "La Coruña",
        state: null,
        phone: "", //"+34 981 132 787",
        email: "galicia@fonestar.es",
      },
      {
        scopes: ["Madrid", "Castilla la Mancha"],
        address_line_01: "Avda. Machupichu, 73",
        address_line_02: null,
        zip: "28043",
        location: "Madrid",
        state: null,
        phone: "", //"+34 913 889 207",
        email: "madrid@fonestar.es",
      },
      {
        scopes: ["Aragón", "Baleares", "Cataluña"],
        address_line_01: "Plaza Tirant lo Blanc, 2",
        address_line_02: null,
        zip: "08005",
        location: "Barcelona",
        state: null,
        phone: "", //"+34 934 105 311",
        email: "catalunya@fonestar.es",
      },
      {
        scopes: ["Comunidad Valenciana", "Canarias", "Murcia"],
        address_line_01: "Avda. de los Tamarindos, 45",
        address_line_02: null,
        zip: "46015",
        location: "Valencia",
        state: null,
        phone: "", //"+34 963 338 128",
        email: "levante@fonestar.es",
      },
      {
        scopes: ["Andalucía", "Extremadura"],
        address_line_01: "Avda. Alcalde L. Uruñuela, 6",
        address_line_02: "Edif. Congreso",
        zip: "41020",
        location: "Sevilla",
        state: null,
        phone: "", //"+34 954 257 814",
        email: "andalucia@fonestar.es",
      },
    ],
    pt: [
      {
        scopes: [],
        address_line_01: "PCI - Parque Ciência e Inovação", //"Rua 28 de Janeiro, nº 350",
        address_line_02: "Via do Conhecimento, Edifício 3-Sala 3.0.2.", //"Candal Park - Fraçao X-13",
        zip: "3830-352", //4400-335",
        location: " Ílhavo", //"Vila Nova de Gaia",
        state: null,
        phone: "+351 966 290 733",
        email: "portugal@fonestar.pt",
        main: true,
      },
    ],
    fr: [
      {
        scopes: [],
        address_line_01: "Adresse uniquement pour livraisons",
        address_line_02: "7 Allée de Chartres",
        zip: "33000",
        location: "Bordeaux",
        state: null,
        phone: "+33 488 620 925",
        email: "france@fonestar.fr",
        main: true,
      },
    ],
    en: [
      {
        scopes: [],
        address_line_01: null,
        address_line_02: null,
        zip: null,
        location: null,
        state: null,
        phone: "+34 628 779 490",
        email: "export@fonestar.es",
        main: true,
      },
    ],
  },
};

export const WP_ROUTES = {
  resourcesCenter: {
    es: `${process.env.REACT_APP_WP_URL}/es/sala-de-prensa/area-de-descargas/`,
    en: `${process.env.REACT_APP_WP_URL}/en/press-room/download-area/`,
    fr: `${process.env.REACT_APP_WP_URL}/fr/salle-de-presse/zone-de-telechargements/`,
    pt: `${process.env.REACT_APP_WP_URL}/pt/dsala-de-imprensa/descarregar-documentos/`,
  },
  distributors: {
    es: `${process.env.REACT_APP_WP_URL}/contacto/hazte-distribuidor/`,
    en: `${process.env.REACT_APP_WP_URL}/en/contact/become-a-distributor/`,
    fr: `${process.env.REACT_APP_WP_URL}/fr/contact/devenez-distributeur/`,
    pt: `${process.env.REACT_APP_WP_URL}/pt/contacto/seja-distribuidor/`,
  },
  projectDesign: {
    es: `${process.env.REACT_APP_WP_URL}/contacto/disena-tu-proyecto/`,
    en: `${process.env.REACT_APP_WP_URL}/en/contact/design-your-project/`,
    fr: `${process.env.REACT_APP_WP_URL}/fr/contact/concevez-votre-projet/`,
    pt: `${process.env.REACT_APP_WP_URL}/pt/contacto/desenhe-o-seu-projeto/`,
  },
  legal: {
    es: `${process.env.REACT_APP_WP_URL}/aviso-legal/`,
    en: `${process.env.REACT_APP_WP_URL}/en/legal-notice/`,
    fr: `${process.env.REACT_APP_WP_URL}/fr/avis-legal/`,
    pt: `${process.env.REACT_APP_WP_URL}/pt/aviso-legal/`,
  },
};

/**
 * Traducciones para las rutas públicas de la aplicación.
 */
export const PUBLIC_ROUTES = {
  es: {
    reset: "recuperar-acceso",
    signUp: "solicitar-acceso",
    maintenance: "mantenimiento",
  },
  en: {
    reset: "reset-password",
    signUp: "sign-up",
    maintenance: "maintenance",
  },
  fr: {
    reset: "reinitialiser-le-mot-de-passe",
    signUp: "sinscrire",
    maintenance: "maintenance",
  },
  pt: {
    reset: "redefinir-senha",
    signUp: "inscrever-se",
    maintenance: "manutenção",
  },
};

/**
 * Posibles estados de los formularios.
 */
export const FORM_STATUS = {
  ready: "ready",
  pending: "pending",
  error: "error",
};

/**
 * Posibles estado cuado se añaden productos al carrito.
 * SET: Machaca la cantidad a la inxdicada.
 * ADD: Añade o suma esas unidades.
 */
export const ADD_PRODUCT_TYPE = {
  set: "set",
  add: "add",
};

/**
 * Posibles valores para mostrar o no notificación cuando se añade un artículo al carrito.
 */
export const SHOW_NOTIFICATION_ADD_TO_CART = {
  no: false,
  yes: true,
};

/**
 * Traducciones para las rutas de la aplicación.
 */
export const ROUTES = {
  es: {
    login: "iniciar-sesion",
    cart: {
      index: "carrito",
      thanks: "gracias",
    },
    rmas: {
      index: "rmas",
      nuevo: "Nuevo",
      estado: "Estados",
    },
    categories: "categorias",
    products: "productos",
    search: "buscador",
    orders: {
      index: "pedidos",
    },
    projects: "proyectos",
    novedades: "nuevos-productos",
    masvendidos: "mas-vendidos",
    webServices: {
      index: "servicios-web",
      thanks: "gracias",
    },
    api: "https://fonestar.com/api.html",
    fonestarFidelity: "fonestar-fidelity",
    contact: "contacto",
    legal: {
      index: "legal",
      warranty: "condiciones-de-venta-y-garantia",
      rma: "autorizacion-retorno-de-mercancia",
    },
    profile: {
      index: "mis-datos",
      favs: "productos-favoritos",
      notifications: "mensajes",
    },
    fuerasDeSerie: {
      index: "fueras-de-serie",
      promos: "promociones",
      megaPromos: "mega-promos",
      superPromos: "super-promos",
      agujas: "agujas",
    },
    outlet: {
      index: "outlet",
      discontinued: "antiguos-y-descontinuados",
      used: "usados-y-con-defectos-esteticos",
      packing: "defectos-de-embalaje",
    },
    budgets: {
      index: "presupuestos",
      viewBudget: "ver-presupuesto",
    },
    newBudget: {
      index: "nuevo-presupuesto",
      thanks: "gracias",
    },
    help: "ayuda",
    clients: "clientes",
    import: "importar-productos",
  },
  en: {
    login: "login",
    cart: {
      index: "cart",
      thanks: "thank-you",
    },
    rmas: {
      index: "rmas",
      nuevo: "New",
      estado: "Status",
    },
    categories: "categories",
    products: "products",
    search: "browser",
    orders: {
      index: "orders",
    },
    projects: "projects",
    novedades: "new-products",
    masvendidos: "best-sellers",
    webServices: {
      index: "web-services",
      thanks: "thanks",
    },
    api: "https://fonestar.com/api.html",
    fonestarFidelity: "fonestar-fidelity",
    contact: "contact",
    legal: {
      index: "legal",
      warranty: "sale-conditions-and-warranty",
      rma: "return-of-merchandise-authorisation",
    },
    profile: {
      index: "my-data",
      favs: "favourite-products",
      notifications: "messages",
    },
    fuerasDeSerie: {
      index: "out-of-series",
      promos: "promotions",
      megaPromos: "mega-promos",
      superPromos: "super-promos",
      agujas: "needles",
    },
    outlet: {
      index: "outlet",
      discontinued: "old-and-discontinued",
      used: "used-and-with-aesthetic-defects",
      packing: "packaging-defects",
    },
    budgets: {
      index: "budgets",
      viewBudget: "view-budget",
    },
    newBudget: {
      index: "new-budget",
      thanks: "thanks",
    },
    help: "ayuda",
    clients: "clients",
    import: "import-products",
  },
  fr: {
    login: "connexion",
    cart: {
      index: "panier",
      thanks: "merci",
    },
    rmas: {
      index: "rmas",
      nuevo: "Nouveau",
      estado: "États",
    },
    categories: "categories",
    products: "produits",
    search: "moteur-de-recherche",
    orders: {
      index: "commandes",
    },
    projects: "projects",
    novedades: "nouveaux-produits",
    masvendidos: "les-plus-vendus",
    webServices: {
      index: "services-web",
      thanks: "merci",
    },
    api: "https://fonestar.com/api.html",
    fonestarFidelity: "fonestar-fidelity",
    contact: "contact",
    legal: {
      index: "legal",
      warranty: "conditions-de-vente-et-garantie",
      rma: "autorisation-retour-de-marchandise",
    },
    profile: {
      index: "mes-donnees",
      favs: "produits-favoris",
      notifications: "messages",
    },
    fuerasDeSerie: {
      index: "hors-serie",
      promos: "promotions",
      megaPromos: "mega-promos",
      superPromos: "super-promos",
      agujas: "aiguilles",
    },
    outlet: {
      index: "outlet",
      discontinued: "anciens et discontinus",
      used: "usage-et-sans-defauts-esthetiques",
      packing: "defauts-demballage",
    },
    budgets: {
      index: "budget",
      viewBudget: "voir-budget",
    },
    newBudget: {
      index: "nouvelle-budget",
      thanks: "merci",
    },
    help: "ayuda",
    clients: "clients",
    import: "import-produits",
  },
  pt: {
    login: "iniciar-sessao",
    cart: {
      index: "carrinho",
      thanks: "obrigado",
    },
    rmas: {
      index: "rmas",
      nuevo: "Novo",
      estado: "Estados",
    },
    categories: "categorias",
    products: "produtos",
    search: "caixa de pesquisa",
    orders: {
      index: "pedidos",
    },
    projects: "projetos",
    novedades: "novos-produtos",
    masvendidos: "mais-vendidos",
    webServices: {
      index: "servicos-web",
      thanks: "obrigado",
    },
    api: "https://fonestar.com/api.html",
    fonestarFidelity: "fonestar-fidelity",
    contact: "contato",
    legal: {
      index: "legal",
      warranty: "condicoes-de-venda-e-garantia",
      rma: "autorizacao-devolucao-de-mercadoria",
    },
    profile: {
      index: "os-meus-dados",
      favs: "produtos-favoritos",
      notifications: "mensagens",
    },
    fuerasDeSerie: {
      index: "foras-de-serie",
      promos: "promoções",
      megaPromos: "mega-promos",
      superPromos: "super-promos",
      agujas: "agulhas",
    },
    outlet: {
      index: "outlet",
      discontinued: "antigos-e-descontinuados",
      used: "usados-e-com-defeitos-esteticos",
      packing: "defeitos-de-embalagem",
    },
    budgets: {
      index: "orcamentos",
      viewBudget: "veja-orcamento",
      thanks: "obrigado",
    },
    newBudget: {
      index: "novo-orcamentos",
      thanks: "obrigado",
    },
    help: "ayuda",
    clients: "clientes",
    import: "importacao-produtos",
  },
};
