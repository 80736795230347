import { API_URL, DEFAULT_REQUEST_CONFIG } from "../../global";
import { constructInitRequestConfig, defaultFetcher } from "../../helpers";

/**
 * @description Añade un artículo a los favoritos.
 * @param {string} language Idioma.
 * @param {string} token Token para autenticar la petición.
 * @param {string} productCode Código del producto a añadir.
 * @returns {Promise}
 */
export async function addItem(language, token, productCode) {
  return defaultFetcher(
    `${API_URL}/favoritos`,
    constructInitRequestConfig(
      { ...DEFAULT_REQUEST_CONFIG, method: "POST" },
      {
        lng: language,
        fstoken: token,
        articulo: productCode,
      }
    )
  );
}
