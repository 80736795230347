import { useTranslation } from "react-i18next";
import "./styles/MultiCarouselDot.scss";

export const MultiCarouselDot = ({ onClick, ...rest }) => {
  const { t } = useTranslation();

  return (
    <span
      role="button"
      aria-label={`${t("ir_a_la_diapositiva")} ${rest.index + 1}`}
      onClick={() => onClick()}
      className={`components-ui-multi-carousel-dot ${
        rest.active ? "active" : ""
      }`.trim()}
    ></span>
  );
};
