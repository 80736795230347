import { createContext, useState } from "react";

const NotificationsContext = createContext({
  notifications: [],
  addNotification: () => {},
  removeNotification: () => {},
  clearNotifications: () => {},
});

const NotificationsProvider = (props) => {
  const [notifications, setNotifications] = useState([]);

  const addNotification = (title, text, variant) => {
    setNotifications([
      ...notifications,
      { id: crypto.randomUUID(), title: title, text: text, variant: variant },
    ]);
  };

  const removeNotification = (id) => {
    setNotifications(
      notifications.filter((notification) => notification.id !== id)
    );
  };

  const clearNotifications = () => {
    setNotifications([]);
  };

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        addNotification,
        removeNotification,
        clearNotifications,
      }}
    >
      {props.children}
    </NotificationsContext.Provider>
  );
};

export { NotificationsProvider };
export default NotificationsContext;
